<script lang="ts">
	import { activeSubmenu } from "../stores/activeSubmenu.js";
	import { showHeaderStore } from "../stores/showHeaderStore.js";
	import type { Image } from "../../../core/schema/Image.js";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import type { MenuItem } from "../../../core/schema/MenuItem.js";
	import type { SubmenuItem } from "../../../core/schema/SubmenuItem.js";

	export let showHeader: boolean;
	export let scrollPosition: number;

	let activeImage: Image | undefined;

	function handleMouseEnter(menuItem: SubmenuItem): void {
		if ("image" in menuItem) {
			activeImage = menuItem.image;
		}
	}

	let bufferedMenuItem: MenuItem | null = null;
	$: {
		if ($activeSubmenu) {
			bufferedMenuItem = $activeSubmenu;
			activeImage =
				"submenus" in bufferedMenuItem ? bufferedMenuItem.submenus.find((submenu) => submenu.image)?.image : undefined;
		}
	}

	function handleOutsideClick(): void {
		activeSubmenu.set(null);
	}
</script>

<button
	class="fixed inset-0 z-20 cursor-default"
	class:hidden={!$activeSubmenu || !showHeader}
	on:click={handleOutsideClick}
></button>

<div
	class="{scrollPosition === 0 && 'border-t-1'} border-b-1 {$activeSubmenu !== null && (showHeader || $showHeaderStore)
		? 'translate-y-[calc(100%+6.9rem)]'
		: ''} min-[1981px]:border-x-1 fixed -top-[34rem] left-0 z-30 hidden min-h-[34rem] w-full max-w-[123.75rem] border-gray-200 bg-white py-10 transition-transform duration-300 lg:block min-[1981px]:left-1/2 min-[1981px]:-translate-x-1/2"
>
	<div class="container grid grid-cols-2">
		<div class="my-8">
			<p class="font-semibold uppercase text-[#DADADA]">{bufferedMenuItem?.name}</p>

			<div class="mt-14 grid w-fit grid-cols-2 gap-x-4 gap-y-16">
				{#if bufferedMenuItem && "submenus" in bufferedMenuItem}
					{#each bufferedMenuItem.submenus.filter((menuItem) => !menuItem.onlyInFooter) as menuItem}
						<a
							class="text-secondary hover:text-primary w-[15rem] text-lg font-semibold"
							href="/{menuItem.href}"
							on:mouseenter={() => {
								handleMouseEnter(menuItem);
							}}
						>
							{menuItem.name}
						</a>
					{/each}
				{/if}
			</div>
		</div>

		<div class="h-[29rem] w-[29rem]">
			{#if activeImage}
				<DynamicPicture
					image={activeImage}
					width={500}
					height={500}
					alt=""
					bucket="storage"
					class="h-full w-full"
					imgClass="rounded-lg object-cover"
				/>
			{/if}
		</div>
	</div>
</div>
