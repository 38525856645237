import { roundToPlaces } from "../../utils/roundToPlaces.js";
import type { Address } from "../Address.js";
import type { Branch } from "../Branch.js";
import type { Order } from "../Order.js";
import type { OrderCreateInputUnion } from "../OrderCreateInputUnion.js";
import type { OrderPrices, OrderPricesWithUnknownDeliveryPrice } from "../OrderPrices.js";
import type { PriceLevel } from "../PriceLevel.js";
import { TaxLevel } from "../TaxLevel.js";
import { calculatePriceWithoutTax } from "./calculatePriceWithoutTax.js";
import { getPriceOfDelivery } from "./getPriceOfDelivery.js";
import { getPriceOfOrderProduct } from "./getPriceOfOrderProduct.js";
import { getSpreadedProducts } from "./getSpreadedProducts.js";
import { getSpreadedVouchers } from "./getSpreadedVouchers.js";
import { hasKnownDeliveryPrice } from "./hasKnownDeliveryPrice.js";

type OrderInput =
	| Pick<Order, "products" | "vouchers" | "customerRequirement" | "surcharges">
	| Pick<OrderCreateInputUnion, "products">;

export function getPriceOfOrder(order: OrderInput, priceLevel: PriceLevel, destination: null): OrderPrices;
export function getPriceOfOrder(
	order: OrderInput,
	priceLevel: PriceLevel,
	destination: Branch | Address | null,
): OrderPricesWithUnknownDeliveryPrice;
export function getPriceOfOrder(
	order: OrderInput,
	priceLevel: PriceLevel,
	destination: Branch | Address | null,
): OrderPricesWithUnknownDeliveryPrice {
	let withTax = 0;
	let withoutTax = 0;

	/*
	 * Spread products before calculating the price, otherwise price is not calculated precisely.
	 * Random UUID is not important in this context.
	 */
	const spreadedProducts = getSpreadedProducts(order.products, () => "");
	const spreadedVouchers = getSpreadedVouchers("vouchers" in order ? order.vouchers : [], () => "");

	for (const product of spreadedProducts) {
		const { withTax: productWithTax, withoutTax: productWithoutTax } = getPriceOfOrderProduct(product);
		withTax += productWithTax;
		withoutTax += productWithoutTax;
	}

	for (const voucher of spreadedVouchers) {
		withTax += voucher.orderVoucherProduct.value * voucher.quantity;
		withoutTax += voucher.orderVoucherProduct.value * voucher.quantity;
	}

	if ("customerRequirement" in order && order.customerRequirement) {
		withTax += order.customerRequirement.price;
		withoutTax += calculatePriceWithoutTax(order.customerRequirement.price, TaxLevel.TaxLevel12);
	}

	if ("surcharges" in order && order.surcharges !== undefined) {
		if (order.surcharges.lateOrderSurcharge !== undefined) {
			withTax += order.surcharges.lateOrderSurcharge.withTax;
			withoutTax += order.surcharges.lateOrderSurcharge.withoutTax;
		}
	}

	let deliveryPrice: OrderPricesWithUnknownDeliveryPrice["deliveryPrice"] = 0;
	let deliveryPriceWithoutTax: OrderPricesWithUnknownDeliveryPrice["deliveryPriceWithoutTax"] = 0;

	if (destination !== null && withTax > 0) {
		const price = getPriceOfDelivery(withTax, destination);
		if (price === null) {
			deliveryPrice = null;
			deliveryPriceWithoutTax = null;
		} else if (price === "unreachable") {
			deliveryPrice = "unreachable";
			deliveryPriceWithoutTax = "unreachable";
		} else {
			deliveryPrice = price.withTax;
			deliveryPriceWithoutTax = price.withoutTax;
		}
	}

	const deliveryPriceObject = { deliveryPrice, deliveryPriceWithoutTax };
	if (hasKnownDeliveryPrice(deliveryPriceObject)) {
		withTax += deliveryPriceObject.deliveryPrice;
		withoutTax += deliveryPriceObject.deliveryPriceWithoutTax;
	}

	return {
		deliveryPrice,
		deliveryPriceWithoutTax,
		totalPrice: {
			priceLevel,
			withoutTax: roundToPlaces(withoutTax, 2),
			withTax,
		},
	};
}
