<script lang="ts">
	import { isMenuActive } from "../../utils/isMenuActive.js";
	import Link from "../Link.svelte";
	import { createEventDispatcher, getContext } from "svelte";
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import Icon from "../../components/Icon.svelte";
	import arrowDropDown from "../../../../core/assets/icons/arrow-drop-down.svg?raw";
	import { activeSubmenu } from "../../stores/activeSubmenu.js";

	export let menu: MenuItem[];

	const pageSlug = getContext<string>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	const menuItemLiClasses =
		"menu-item lg:[&:hover>div>.menu-link]:bg-theme lg:[&:hover>div>.menu-link]:text-white lg:[&:hover_.navigation]:pointer-events-auto lg:[&:hover_.navigation]:opacity-100";
	const menuItemLinkClasses =
		"lg:whitespace-nowrap lg:py-[0.35rem] lg:text-[.95rem] xl:text-base uppercase font-semibold px-3 leading-[1.25] 2xl:py-2";

	function handleChange(menuItem: MenuItem): void {
		if ($activeSubmenu?.id === menuItem.id) {
			activeSubmenu.set(null);
		} else {
			activeSubmenu.set(menuItem);
		}
	}

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div
	class="mt-8 flex grow justify-center overflow-y-auto overflow-x-hidden lg:mt-0 lg:h-full lg:w-auto lg:overflow-y-hidden"
>
	<ul class="flex flex-col items-center gap-8 lg:flex-row lg:justify-center lg:gap-4">
		<li class="sm:text-2xs text-[0.6rem] font-semibold uppercase leading-4 tracking-[0.15rem] lg:hidden">Menu</li>
		{#each menu.filter((menuItem) => ("onlyInFooter" in menuItem ? !menuItem.onlyInFooter : true)) as menuItem}
			{#if "submenus" in menuItem && menuItem.submenus.length > 0}
				<li
					class="{menuItemLiClasses} hover:text-primary hidden cursor-pointer transition-colors duration-300 lg:block"
					class:text-primary={$activeSubmenu?.id === menuItem.id}
				>
					<button
						on:click={() => {
							handleChange(menuItem);
						}}
						class="flex"
					>
						<span class="{menuItemLinkClasses} !px-0">{menuItem.name}</span>
						<Icon
							icon={arrowDropDown}
							class="transition-transform duration-300 {$activeSubmenu?.id === menuItem.id && 'rotate-180'}"
						/>
					</button>
				</li>

				{#each menuItem.submenus.filter((menuItem) => !menuItem.onlyInFooter) as submenuItem}
					<li class="{menuItemLiClasses} lg:hidden">
						<div class="group relative w-full">
							<Link
								noUnderline
								class="{menuItemLinkClasses} hover:text-primary transition-colors duration-300 {isMenuActive(
									submenuItem,
									pageSlug,
								)
									? 'underline !decoration-black underline-offset-4'
									: ''}"
								href={`/${submenuItem.href}`}
								target={submenuItem.target}
								on:click={() => {
									dispatch("click", submenuItem);
								}}
							>
								{submenuItem.name}
							</Link>
						</div>
					</li>
				{/each}
			{:else if "href" in menuItem && "target" in menuItem}
				<li class={menuItemLiClasses}>
					<div class="group relative w-full">
						<Link
							noUnderline
							class="{menuItemLinkClasses} hover:text-primary transition-colors duration-300 {isMenuActive(
								menuItem,
								pageSlug,
							)
								? 'underline !decoration-black underline-offset-4'
								: ''}"
							href={`/${menuItem.href}`}
							target={menuItem.target}
							on:click={() => {
								dispatch("click", menuItem);
							}}
						>
							{menuItem.name}
						</Link>
					</div>
				</li>
			{/if}
		{/each}
	</ul>
</div>
