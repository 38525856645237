import { Locale, type LocalizedString } from "./Locale.js";

export enum TaxLevel {
	TaxLevel10 = "TaxLevel10",
	TaxLevel12 = "TaxLevel12",
	TaxLevel15 = "TaxLevel15",
	TaxLevel21 = "TaxLevel21",
}

export const taxLevelNameMap: Record<TaxLevel, LocalizedString> = {
	[TaxLevel.TaxLevel10]: {
		[Locale.cs]: "10 %",
	},
	[TaxLevel.TaxLevel12]: {
		[Locale.cs]: "12 %",
	},
	[TaxLevel.TaxLevel15]: {
		[Locale.cs]: "15 %",
	},
	[TaxLevel.TaxLevel21]: {
		[Locale.cs]: "21 %",
	},
};

export const taxLevelValueMap: Record<TaxLevel, number> = {
	[TaxLevel.TaxLevel10]: 10,
	[TaxLevel.TaxLevel12]: 12,
	[TaxLevel.TaxLevel15]: 15,
	[TaxLevel.TaxLevel21]: 21,
};

export const surchargeTaxLevel = TaxLevel.TaxLevel21;
