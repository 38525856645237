<script lang="ts">
	import type { ActionReturn } from "svelte/action";

	let iconClass = "";
	export { iconClass as class };
	export let icon: string;

	let handledManually = false;
	// @html tag does not correctly update on mount. this updates it correctly.
	function handleManually(element: HTMLElement, icon: string): ActionReturn<string> {
		function handle(icon: string): void {
			handledManually = true;
			element.innerHTML = icon;
		}

		handle(icon);
		return {
			update: handle,
		};
	}

	/* eslint-disable svelte/no-at-html-tags */
</script>

<!-- Setting min and max width/height because Safari 16 is a bad boy and refuses to work properly when setting width/height -->
<div
	use:handleManually={icon}
	class="icon flex aspect-square shrink-0 items-center justify-center [&>svg]:max-h-full [&>svg]:min-h-full [&>svg]:min-w-full [&>svg]:max-w-full {iconClass}"
>
	{#if !handledManually}
		{@html icon}
	{/if}
</div>
